import {ListItems, TreePreViewItem} from 'interfaces/interfaces'
import {cloneNode} from "../services/dom.service";

export const checkUndefined = (str: string | undefined | ListItems) => {
	return str ? str : ''
}

export const unpuckNestedValue = (
	object: ListItems,
	value: string,
	attributes: string[]
): ListItems => {
	let newObj: ListItems = {
		...object
	}

	if (attributes.length === 1) {
		newObj = {
			...newObj,
			[attributes[0]]: checkUndefined(newObj[attributes[0]]) + ' ' + value
		}
	} else {
		const shiftAttribute = attributes.shift()
		newObj = {
			...newObj,
			[shiftAttribute!]: {
				...(newObj[shiftAttribute!] as ListItems),
				...unpuckNestedValue(
					newObj[shiftAttribute!] as ListItems,
					value,
					attributes
				)
			}
		}
	}

  return newObj
}

export const getTypeAndTextFromTypedNode = (node: HTMLDivElement) => {
	const clonedNode = cloneNode(node)
	const spanElement: HTMLSpanElement | null =
		clonedNode.querySelector('.__typed_span__')
	const type = spanElement?.innerText.toLowerCase()
	spanElement?.remove()
	const text = clonedNode.innerText

	return [type, text]
}

export const findParent = (nodes: Array<TreePreViewItem>, element: TreePreViewItem, parentElement: TreePreViewItem | null): TreePreViewItem | null => {
	for (const node of nodes) {
		if (node.id === element.id) {
			return parentElement;
		}

		if (node.children && Array.isArray(node.children)) {
			const result = findParent(node.children, element, node);
			if (result !== null) {
				return result;
			}
		}
	}

	return null;
}

export const findNode = (nodes: Array<TreePreViewItem>, element: TreePreViewItem): TreePreViewItem | null => {
	for (const node of nodes) {
		if (node.id === element.id) {
			return node;
		}

		if (node.children && Array.isArray(node.children)) {
			const result = findNode(node.children, element);
			if (result !== null) {
				return result;
			}
		}
	}
	return null
}
