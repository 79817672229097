import React, {FC, useState} from 'react';
import Typography from '@mui/material/Typography';
import {Accordion, AccordionSummary, AccordionDetails} from '../../../shared/MyAccrodionComponents';
import {useDnDPreView} from "../../../hooks/useDnDPreView";
import {TreePreViewItem} from "../../../interfaces/interfaces";

import './AccordionList.scss';

interface AccordionListProps {
    item: TreePreViewItem
    onChangePreView: (target: TreePreViewItem, destination: TreePreViewItem, type: 'summary' | 'detail' | 'wrapper') => void
}

const AccordionList: FC<AccordionListProps> = ({item, onChangePreView}) => {

    const {target, destination, setTarget, setDestination} = useDnDPreView()
    const [expanded, setExpanded] = React.useState(true);
    const [isDroppable, setIsDroppable] = useState(false)

    const handleChange = () => {
        setExpanded(prev => !prev)
    }

    const handleDragStart: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation()
        setTarget(item)
    }

    const handleDragOver: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setDestination(item)
        if (isDroppable) return;
        setIsDroppable(true)
    }

    const handleDragLeave = () => {
        if (!isDroppable) return;
        setIsDroppable(false)
    }

    const handleDropSummary: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation()
        event.preventDefault()
        setIsDroppable(false)
        if (!target || !destination) return;
        onChangePreView(target, destination, 'summary')
    }

    const handleDropDetail: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation()
        event.preventDefault()
        setIsDroppable(false)
        console.log({target, destination})
        if (!target || !destination) return;
        onChangePreView(target, destination, 'detail')
    }

    return (
        <Accordion expanded={expanded} onChange={handleChange} draggable onDragStart={handleDragStart}>
            <AccordionSummary  onDrop={handleDropSummary} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
                <Typography>{item.title}</Typography>
            </AccordionSummary>
            <div onDrop={handleDropDetail} onDragOver={handleDragOver} onDragLeave={handleDragLeave} style={{backgroundColor: isDroppable ? 'rgba(92, 219, 148, 0.6)' : 'transparent'}}>
                <AccordionDetails>
                    <div className={'detail-wrapper'}>
                        <p contentEditable={true} className="detail-content">{item.content}</p>
                        {item.children.length !== 0 && item.children.map(item => <AccordionList key={item.id} item={item} onChangePreView={onChangePreView}/>)}
                    </div>
                </AccordionDetails>
            </div>
        </Accordion>
    );
}

export default AccordionList;