import { AuthContext } from 'Context/context'
import {AccessNames, User} from 'interfaces/interfaces'
import React, { FC, useCallback, useState } from 'react'

interface AuthProviderProps {
	children: React.ReactNode
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
	const [user, setUser] = useState<User>(process.env.NODE_ENV === 'production' ? {
		userName: '',
		accessList: []
	} : {
		userName: 'Иван Иванов',
		accessList: [AccessNames.SAVE, AccessNames.READ, AccessNames.UPLOAD]
	})

	const signIn = useCallback((user: User) => {
		setUser(user)
	}, [])

	return (
		<AuthContext.Provider value={{ user, signIn }}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider
