import React, { Dispatch, FC, SetStateAction, memo } from 'react'

import { AccessNames, RequirementItem } from '../../interfaces/interfaces'

import RequirementsItem from './RequirementsItem/RequirementsItem'
import './RequirementsList.scss'

interface RequirementsListProps {
	data: RequirementItem[] | undefined
	loading: boolean
	error: string
	setData: Dispatch<SetStateAction<RequirementItem[] | undefined>>
	userPermission: AccessNames[]
	onSettingClick: (id: string) => void
}

const RequirementsList: FC<RequirementsListProps> = ({
	data,
	loading,
	error,
	setData,
	userPermission,
	onSettingClick
}) => {
	if (loading) {
		return <div className="req-list">Загрузка...</div>
	}

	if (error) {
		return <div className="req-list">Ошибка. Повторите попытку позже.</div>
	}

	return (
		<div className="req-list">
			{data &&
				data.map((requirement, idx) => {
					return (
						<div key={requirement.id}>
							<RequirementsItem
								onSettingClick={onSettingClick}
								requirement={requirement}
								docStatus={requirement.state}
								setData={setData}
								userPermission={userPermission}
							/>
							{idx !== data.length - 1 && <hr />}
						</div>
					)
				})}
		</div>
	)
}

export default memo(RequirementsList)
