import React, { FC, MouseEventHandler, useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { ChangedDocContext } from '../../../Context/context'

import './MenuItem.scss'

interface MenuItemProps {
	icon: string
	title: string
	href: string
}

const MenuItem: FC<MenuItemProps> = ({ icon, title, href }) => {
	const [isDocChanged, , clearDocChanged] = useContext(ChangedDocContext)

	const handleClickLink: MouseEventHandler<HTMLAnchorElement> | undefined = (
		event
	) => {
		if (isDocChanged.current.state) {
			const isLeave = window.confirm('Выйти без сохранения?')
			if (!isLeave) {
				event.stopPropagation()
				event.preventDefault()
			} else {
				clearDocChanged()
			}
		}
	}

	const observer = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutationRecord) {})
	})

	const targetArr = document.querySelectorAll('.page-content')
	targetArr.forEach((item) => {
		observer.observe(item, { attributes: true, attributeFilter: ['style'] })
	})

	return (
		<NavLink to={href} className="menu-item" onClick={handleClickLink}>
			<img src={icon} alt="Картинка меню" />
			<p>{title}</p>
		</NavLink>
	)
}

export default MenuItem
