import { useOptions } from 'hooks/useOptions'
import moment from 'moment'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { validation } from 'services/fetch.service'

import { host } from '../../Environment'
import {
	CountriesResponse,
	CreateRequirementRequest,
	DocumentsResponse,
	ReqFormInputs,
	RequirementItem
} from '../../interfaces/interfaces'
import { MuiCustomMenuItem } from '../../shared/MySelectedComponents'
import AutocompleteInput from '../Controls/AutocompleteInput/AutocompleteInput'
import Button from '../Controls/Buttons/Button/Button'
import DatePicker from '../Controls/DatePicker/DatePicker'
import Input from '../Controls/Input/Input'
import MyMuiSelect from '../Controls/MyMuiSelect/MyMuiSelect'

import CheckboxWithlabel from './CheckboxWithlabel/CheckboxWithlabel'
import LabelWithControl from './LabelWithControl/LabelWithControl'
import PopupClose from './PopupClose/PopupClose'
import PopupTitle from './PopupTitle/PopupTitle'
import { useDeferFetch } from "hooks/fetch.hooks";

interface AddRequirementFormProps {
	isUpdate: boolean
	initialState: ReqFormInputs
	closeModal: () => void
	setData: Dispatch<SetStateAction<RequirementItem[] | undefined>>
	onSuccessSave: () => void
}

const AddRequirementForm: FC<AddRequirementFormProps> = ({
	isUpdate,
	closeModal,
	onSuccessSave,
	initialState
}) => {

	const { options: documents, handleChange: handleChangeDocumentInput } =
		useOptions<DocumentsResponse>('/Requirement/doctype')
	const { options: countries, handleChange: handleChangeImporterInput } =
		useOptions<CountriesResponse>('/Requirement/clwrldcntr')

	const {onFetch: createRequirement} = useDeferFetch(`/Requirement/Create`)
	const {onFetch: updateRequirement} = useDeferFetch(`/Requirement/update`)

	const {
		handleSubmit,
		formState: { isValid },
		control,
		setError,
		watch,
		setValue
	} = useForm<ReqFormInputs>({
		mode: 'onChange',
		defaultValues: initialState
	})

	const watchFields = watch()

	const setMonthWhereDateToChange = (date: Date | null) => {
		if (watchFields.dateWith === null) return
		setValue(
			'months',
			moment(date).diff(watchFields.dateWith, 'month').toString()
		)
	}

	const setMonthWhereDateWithChange = (date: Date | null) => {
		if (watchFields.dateTo === null) return
		setValue(
			'months',
			moment(watchFields.dateTo).diff(date, 'month').toString()
		)
	}

	const setDateToWhereMonthChange = (months: string) => {
		if (watchFields.dateWith === null) return
		setValue(
			'dateTo',
			moment(watchFields.dateWith).add(months, 'month').toDate()
		)
	}

	const onSubmit: SubmitHandler<ReqFormInputs> = (data) => {

		const body: CreateRequirementRequest = {
			id: data.id ? data.id : null,
			importer_id: data.importer!.id,
			period_beg: data.dateWith ? new Date(data.dateWith) : null,
			period_end: data.dateTo ? new Date(data.dateTo) : null,
			permission: !!data.permission,
			permonths: +data.months,
			pp: data.pp,
			relevance: data.actuality,
			reqdate: data.date,
			reqnumb: data.docNumber,
			reqtype_id: data.doc!.id
		}

		if (isUpdate) {
			updateRequirement(body)
				.then(() => {
					onSuccessSave()
					closeModal()
				})
		} else {
			createRequirement(body)
				.then(() => {
					onSuccessSave()
					closeModal()
				})
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ padding: '20px 20px' }}>
			<div className="req-form-wrap">
				<PopupTitle
					title="Добавить требование"
					style={{ gridArea: '1 / 1 / 2 / 9' }}
				/>
				<PopupClose onClose={closeModal} style={{ justifySelf: 'end' }} />
				<LabelWithControl
					label="№ п.п"
					style={{ gridColumn: '1 / 2', gridRow: '2 / 3' }}
				>
					<Input
						style={{ gridColumn: '2 / 4' }}
						control={control}
						name="pp"
						rules={{ required: 'Поле обязательно для заполнения' }}
					/>
				</LabelWithControl>
				<CheckboxWithlabel
					id="relevance"
					label="Актуальность"
					style={{ gridArea: '3 / 1 / 4 / 3' }}
					control={control}
					name="actuality"
				/>
				<LabelWithControl
					label="Разрешить"
					style={{ gridColumn: '1 / 2', gridRow: '4 / 5' }}
				>
					<MyMuiSelect
						style={{ gridColumn: '2 / 4', gridRow: '4 / 5' }}
						control={control}
						name="permission"
					>
						<MuiCustomMenuItem value={'true'}>Да</MuiCustomMenuItem>
						<MuiCustomMenuItem value={'false'}>Нет</MuiCustomMenuItem>
					</MyMuiSelect>
				</LabelWithControl>
				<LabelWithControl
					label="Документ"
					style={{ gridColumn: '1 / 2', gridRow: '5 / 6' }}
				>
					<AutocompleteInput
						options={documents}
						style={{ gridColumn: '2 / 6', gridRow: '5 / 6' }}
						control={control}
						onChangeInput={handleChangeDocumentInput}
						name="doc"
						rules={{ required: 'Поле обязательно для заполнения' }}
						fieldName="code"
					/>
				</LabelWithControl>
				<LabelWithControl
					label="№"
					style={{ gridColumn: '6 / 7', gridRow: '5 / 6' }}
				>
					<Input
						style={{ gridColumn: '7 / 8', gridRow: '5 / 6' }}
						control={control}
						name="docNumber"
						rules={{ required: 'Поле обязательно для заполнения' }}
					/>
				</LabelWithControl>
				<LabelWithControl
					label="Дата"
					style={{ gridColumn: '1 / 2', gridRow: '6 / 7' }}
				>
					<DatePicker
						style={{ gridColumn: '2 / 6', gridRow: '6 / 7' }}
						control={control}
						name="date"
						rules={{ required: 'Поле обязательно для заполнения' }}
						setError={setError}
					/>
				</LabelWithControl>
				<LabelWithControl
					label="Период действия c"
					style={{ gridColumn: '1 / 3', gridRow: '7 / 8' }}
				>
					<DatePicker
						style={{ gridColumn: '3 / 6', gridRow: '7 / 8' }}
						control={control}
						name="dateWith"
						changeFields={setMonthWhereDateWithChange}
					/>
				</LabelWithControl>
				<LabelWithControl
					label="по"
					style={{ gridColumn: '6 / 7', gridRow: '7 / 8' }}
				>
					<DatePicker
						style={{ gridColumn: '7 / 9', gridRow: '7 / 8' }}
						control={control}
						name="dateTo"
						changeFields={setMonthWhereDateToChange}
					/>
				</LabelWithControl>
				<LabelWithControl
					label="Месяцев"
					style={{ gridColumn: '1 / 2', gridRow: '8 / 9' }}
				>
					<Input
						style={{ gridColumn: '2 / 4', gridRow: '8 / 9' }}
						control={control}
						name="months"
						rules={{ required: 'Поле обязательно для заполнения' }}
						changeFields={setDateToWhereMonthChange}
					/>
				</LabelWithControl>
				<LabelWithControl
					label="Импортёр"
					style={{ gridColumn: '1 / 2', gridRow: '9 / 10' }}
				>
					<AutocompleteInput
						options={countries}
						style={{ gridColumn: '2 / 6', gridRow: '9 / 10' }}
						control={control}
						onChangeInput={handleChangeImporterInput}
						name="importer"
						rules={{ required: 'Поле обязательно для заполнения' }}
						fieldName="code"
					/>
				</LabelWithControl>
				<Button
					type="submit"
					title="Сохранить"
					color="primary"
					style={{ gridColumn: '1 / 3', gridRow: '10 / 11' }}
					disabled={!isValid}
				/>
				<Button
					type="button"
					title="Отмена"
					onClick={closeModal}
					color="abort"
					style={{ gridColumn: '3 / 5', gridRow: '10 / 11' }}
				/>
			</div>
		</form>
	)
}

export default AddRequirementForm
