import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { Editor as TinyMCEEditor } from 'tinymce'

import { ListItems } from '../../../interfaces/interfaces'
import { pasteNewContent } from '../../../services/dom.service'
import { validateDataHistory } from '../../../services/saveData.service'
import List from '../List/List'
import SearchInput from '../SearchInput/SearchInput'

interface AutocompleteTypeProps {
	editor: TinyMCEEditor | null
	setOpenDropDown: Dispatch<SetStateAction<boolean>>
	addHistoryItem: Dispatch<
		SetStateAction<{ title: string; func: () => void }[]>
	>
}

const initialState: ListItems = {
	Страна: 'Страна',
	'Методы отбора образцов': 'Методы отбора образцов',
	'Требования к экспорту': {
		Регионы: 'Экспорт Регионы',
		Контрагенты: 'Экспорт Контрагенты'
	},
	'Требования к реэкспорту': {
		Страны: 'Реэкспорт Страны',
		Регионы: 'Реэкспорт Регионы',
		Контрагенты: 'Реэкспорт Контрагенты'
	},
	'Сопроводительные документы': {
		'Основной сопроводительный документ': 'Основной сопроводительный документ',
		'Сопутствующий сопроводительный документ':
			'Сопутствующий сопроводительный документ',
		'Прочий сопроводительный документ': 'Прочий сопроводительный документ',
		'Дополнительные сопроводительные документы':
			'Дополнительные сопроводительные документы '
	},
	'Подкарантинная продукция': {
		'Подкарантинная продукция': 'Подкарантинная продукция',
		'ТН ВЭД': 'ТН ВЭД',
		'Карантинные объекты': 'Карантинные объекты',
		'Подкарантинные объекты': 'Подкарантинные объекты',
		'Требования к подкарантинной продукции':
			'Требования к подкарантинной продукции'
	},
	'Места ввоза': 'Места ввоза',
	Упаковка: 'Упаковка',
	'Условия транзита': 'Условия транзита',
	'Условия транспортировки': 'Условия транспортировки',
	'Подкарантинный объект': 'Подкарантинный объект'
}

const AutocompleteType: FC<AutocompleteTypeProps> = ({
	editor,
	setOpenDropDown,
	addHistoryItem
}) => {
	const [listItems, setListItems] = useState<ListItems>(initialState)
	const [expanded, setExpanded] = useState(false)

	const handleClick = (type: string) => () => {
		if (editor) {
			pasteNewContent(editor, type, setOpenDropDown)
			addHistoryItem((prev) =>
				validateDataHistory(prev, type, () =>
					pasteNewContent(editor, type, setOpenDropDown)
				)
			)
		}
	}

	return (
		<div style={{ width: '100%' }}>
			<SearchInput
				items={initialState}
				setItems={setListItems}
				setExpanded={setExpanded}
			/>
			<List items={listItems} expanded={expanded} handleClick={handleClick} />
		</div>
	)
}

export default AutocompleteType
