import { Autocomplete } from '@mui/material'
import cn from 'classnames'
import React, { CSSProperties, useEffect } from 'react'
import { useController } from 'react-hook-form'

import { FormController } from '../../../interfaces/interfaces'

import './AutocompleteInput.scss'

interface AutocompleteInputProps<T, TOption, TKey extends keyof TOption>
	extends FormController<T> {
	style?: CSSProperties
	options: TOption[]
	onChangeInput: (input: string) => void
	fieldName: TKey
}

const sx = {
	'& input': {
		background: 'transparent',
		outline: 'none',
		border: '1px solid #C4C4C4',
		padding: '5px 10px',
		borderRadius: '10px',
		height: '37px',
		width: '100%',
		fontFamily: 'MontserratMedium, sans-serif',
		fontSize: '16px',
		color: '#05396B',
		transition: 'border-color 0.2s ease',
		'&:focus': {
			borderWidth: '2px',
			borderColor: '#8DE4AF'
		}
	},
	'& .invalid input': {
		borderWidth: '2px',
		borderColor: 'orangered'
	}
}

const AutocompleteInput = <
	T,
	TOption extends { [key in TKey]: string | number},
	TKey extends keyof TOption
>({
	style,
	control,
	name,
	rules,
	options,
	onChangeInput,
	fieldName,
	...props
}: React.PropsWithChildren<
	AutocompleteInputProps<T, TOption, keyof TOption>
>) => {
	const {
		field: { value, onChange },
		fieldState
	} = useController<T>({
		control,
		name,
		rules
	})

	useEffect(() => {
		console.log(value)
	}, [value])

	return (
		<Autocomplete
			disablePortal
			sx={{
				...style,
				...sx
			}}
			options={options}
			isOptionEqualToValue={(option, value) => {
				if (!option) return false
				return option[fieldName] === value[fieldName]
			}}
			getOptionLabel={(option) => {
				if (!option) return ''
				return option[fieldName] as string
			}}
			renderInput={(params) => (
				<div
					className={cn('control-wrap', { invalid: fieldState.error })}
					style={{ width: '100%' }}
					ref={params.InputProps.ref}
				>
					<input {...params.inputProps} />
					{fieldState.error && (
						<p className="error-message">{fieldState.error.message}</p>
					)}
				</div>
			)}
			value={value ? (value as TOption) : null}
			renderOption={(props, option: TOption) => {
				return (
					<li {...props} className="list-item">
						<span>{option[fieldName] ? option[fieldName] : ''}</span>
					</li>
				)
			}}
			onInputChange={(event, value) => {
				if (value) {
					onChangeInput(value)
				} else {
					onChange('')
				}
			}}
			onChange={(event, value, reason) => {
				if (reason === 'selectOption') {
					onChange(value)
				}
			}}
		/>
	)
}

export default AutocompleteInput
