import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import 'normalize.css/normalize.css';
import './index.scss';
import App from './App';


const Main = () => {
    return (
        <React.StrictMode>
            <BrowserRouter>
                    <App/>
            </BrowserRouter>
        </React.StrictMode>
    )
}

ReactDOM.render(
    <Main />,
    document.getElementById('root')
);
