import { useEffect, useState } from 'react'

export const useDebounce = (value: string, delay: number) => {
	const [debValue, setValue] = useState(value)

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setValue(value)
		}, delay)
		return () => clearTimeout(timeoutId)
	}, [delay, value])

	return debValue
}
