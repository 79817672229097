import React from 'react'
import { NavLink } from 'react-router-dom'

import logo from '../../assets/img/logo.png'
import referencIcon from '../../assets/img/ref-icon.png'
import reqIcon from '../../assets/img/req-icon.png'

import './Menu.scss'
import MenuItem from './MenuItem/MenuItem'


const Menu = () => {
  return (
    <div className="menu">
      <div className="logo-wrapper">
        <img src={logo} alt="Логотип" className="logo-img" />
        <p className="logo-text">ФГБУ «Всероссийский центр карантина растений»</p>
      </div>
      <MenuItem icon={reqIcon} title={'Требования'} href={'requirements'} />
     {/* <MenuItem icon={referencIcon} title={'Cправочники'} href={'references'} />*/}
      <a href='https://d.vniikr.ru' className="menu-item" >
        <p>Стандартная система</p>
      </a>
      <a href='/authorization/logout' className="menu-item" >
        <p>Выйти</p>
      </a>
    </div>
  );
};

export default Menu;