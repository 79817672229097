import {ListItems, TreePreViewItem} from 'interfaces/interfaces'
import React, { Dispatch, MutableRefObject } from 'react'
import { cloneNode, createTypedText } from "services/dom.service";
import { Editor as TinyMCEEditor } from 'tinymce'
import {getTypeAndTextFromTypedNode, unpuckNestedValue} from 'utils/utils'
import { host } from '../Environment'
import { notify } from './notify.service'
import {TranslatedHTML} from "../interfaces/translate";

import {v4 as uuid} from 'uuid';

export const addType = (
	editorRef: MutableRefObject<TinyMCEEditor | null>,
	setPositionDropDown: Dispatch<React.SetStateAction<{ x: number; y: number }>>,
	setDropDownRender: Dispatch<React.SetStateAction<JSX.Element | undefined>>,
	dropDownElement: JSX.Element,
	setOpenDropDown: Dispatch<React.SetStateAction<boolean>>,
	buttonGroupNumber: number
) => {
	const toolbar = editorRef.current
		?.getContainer()
		.querySelector('.tox-toolbar__primary')
	if (toolbar) {
		const buttonGroup = toolbar.querySelectorAll('.tox-toolbar__group')
		if (buttonGroup) {
			const button = buttonGroup[buttonGroupNumber]
			const { x, y, width, height } = button.getBoundingClientRect()
			setPositionDropDown({
				x: x - (200 - width),
				y: y + height
			})
			setDropDownRender(dropDownElement)
			setOpenDropDown((prev) => !prev)
		}
	}
}

export const deleteTypeContent = (editor: TinyMCEEditor) => {
	const node = (editor.selection.getNode() as HTMLSpanElement).closest(
		'.__typed__'
	)

	if (!node) return
	const innerSpan = node.querySelector<HTMLSpanElement>('.__typed_span__')

	if (!innerSpan) return

	innerSpan.remove()
	const innerHTML = node.innerHTML
	node.remove()
	editor.selection.setContent(innerHTML)
}

export const deleteTypeAI = (editor: TinyMCEEditor) => {
	const node = (editor.selection.getNode() as HTMLSpanElement).closest(
		'.__typed__ai'
	)

	if (!node) return
	const innerSpan = node.querySelector<HTMLSpanElement>('.__typed_span__')

	if (!innerSpan) return

	innerSpan.remove()
	const innerHTML = node.innerHTML

	editor.selection.select(node)
	editor.selection.setContent(innerHTML, { selection: true })
}

export const approveTypeAI = (editor: TinyMCEEditor) => {
	const node = (editor.selection.getNode() as HTMLSpanElement).closest(
		'.__typed__ai'
	)

	if (!node) return
	const innerSpan = node.querySelector<HTMLSpanElement>('.__typed_span__')

	if (!innerSpan) return
	const innerImg = innerSpan.querySelectorAll('img')
	innerImg.forEach(img => img.remove())

	const type = innerSpan.innerHTML

	innerSpan.remove()
	const innerText = node.innerHTML

	editor.selection.select(node)
	createTypedText(editor, innerText, type)
}

export const saveDoc = async (
	doc: string | null,
	id: string | undefined,
	clearDocChanged: () => void
) => {
	try {
		const response = await fetch(`${host}/Requirement/save`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				idRequirement: id,
				document: doc
			})
		})

		if (response.ok) {
			clearDocChanged()
			notify('Данные успешно сохранены', 'success')
		} else {
			const error = await response.text()
			throw new Error(error)
		}
	} catch (error) {
		if (error instanceof Error) {
			if (error.message === 'DOC_NOT_CHANGED') {
				clearDocChanged()
				notify('Документ не был изменён', 'info')
			}
		} else {
			notify('Что-то пошло не так', 'danger', 'Попробуйте позже')
		}
	}
}

export const getTreePreView = (nodes: NodeListOf<HTMLDivElement>): Array<TreePreViewItem> => {
	let newPreView: Array<TreePreViewItem> = []
	nodes.forEach(node => {
		const [type, text] = getTypeAndTextFromTypedNode(node)
		if (!type || !text) return;
		newPreView = [...newPreView, {
			id: uuid(),
			title: type,
			content: text,
			children: []
		}]
	})
	return newPreView
}

export const getObjectThree = (types: NodeListOf<HTMLDivElement>) => {
	let newViewObj: ListItems = {}
	types.forEach((typedElement) => {
		const [type, typedText] = getTypeAndTextFromTypedNode(typedElement)
		if (!type || !typedText) return;
		switch (type) {
			case 'страна':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, ['Страна'])
				}
				break
			case 'методы отбора образцов':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Методы отбора образцов'
					])
				}
				break
			case 'экспорт регионы':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Требования к экспорту',
						'Регионы'
					])
				}
				break
			case 'экспорт контрагенты':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Требования к экспорту',
						'Контрагенты'
					])
				}
				break
			case 'реэкспорт страны':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Требования к реэкспорту',
						'Страны'
					])
				}
				break
			case 'реэкспорт регионы':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Требования к реэкспорту',
						'Регионы'
					])
				}
				break
			case 'реэкспорт контрагенты':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Требования к реэкспорту',
						'Контрагенты'
					])
				}
				break
			case 'основной сопроводительный документ':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Сопроводительные документы',
						'Основной сопроводительный документ'
					])
				}
				break
			case 'сопутствующий сопроводительный документ':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Сопроводительные документы',
						'Сопутствующий сопроводительный документ'
					])
				}
				break
			case 'прочий сопроводительный документ':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Сопроводительные документы',
						'Прочий сопроводительный документ'
					])
				}
				break
			case 'дополнительные сопроводительные документы':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Сопроводительные документы',
						'Дополнительные сопроводительные документы'
					])
				}
				break
			case 'подкарантинная продукция':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Подкарантинная продукция',
						'Подкарантинная продукция'
					])
				}
				break
			case 'тн вэд':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Подкарантинная продукция',
						'ТН ВЭД'
					])
				}
				break
			case 'карантинные объекты':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Подкарантинная продукция',
						'Карантинные объекты'
					])
				}
				break
			case 'подкарантинные объекты':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Подкарантинная продукция',
						'Подкарантинные объекты'
					])
				}
				break
			case 'требования к подкарантинной продукции':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Подкарантинная продукция',
						'Требования к подкарантинной продукции'
					])
				}
				break
			case 'места ввоза':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, ['Места ввоза'])
				}
				break
			case 'упаковка':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, ['Упаковка'])
				}
				break
			case 'условия транзита':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, ['Условия транзита'])
				}
				break
			case 'условия транспортировки':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, [
						'Условия транспортировки'
					])
				}
				break
			case 'подкарантинный объект':
				newViewObj = {
					...unpuckNestedValue(newViewObj, typedText, ['Подкарантинный объект'])
				}
				break
			default:
				return null
		}
	})
	return newViewObj
}

export const translateFile = async (text: string): Promise<TranslatedHTML | null> => {
	try {
		const response = await fetch(host + `/file/translate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				htmlString: text
			})
		})

		if (response.ok) {
			return await response.json()
		} else {
			return null
		}
	} catch(error) {
		notify('Что-то пошло не так', 'danger', 'Попробуйте позже')
		return null
	}
}
