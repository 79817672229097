import {FormController} from "../../../interfaces/interfaces";
import {useController} from "react-hook-form";
import React, {CSSProperties} from 'react';
import './Input.scss'
import cn from "classnames";

interface InputProps<T> extends FormController<T> {
    style?: CSSProperties
    changeFields?: (value: string) => void
}

const Input = <T, >({style, control, name, rules, changeFields, ...props}: React.PropsWithChildren<InputProps<T>>) => {

    const {field, fieldState} = useController({
        control,
        name,
        rules
    })

    return (
        <div className="control-wrap" style={style}>
            <input type="text" className={cn('control-input', {invalid: fieldState.error})} onChange={(event) => {
                field.onChange(event)
                changeFields && changeFields(event.target.value)
            }}
                   value={field.value as string} {...props}/>
            {fieldState.error && <p className="error-message">{fieldState.error.message}</p>}
        </div>
    )


};

export default Input;