import React, {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useEffect
} from 'react'
import { useNavigate } from 'react-router-dom'

import { host } from '../../../Environment'
import editImg from '../../../assets/img/edit_doc.svg'
import uploadImg from '../../../assets/img/upload_doc.svg'
import settingImg from '../../../assets/img/edit.png'
import {
	AccessNames,
	DocStatus,
	RequirementItem
} from '../../../interfaces/interfaces'
import ButtonImg from '../../Controls/Buttons/ButtonImg/ButtonImg'
import UploadFileBtn from '../../Controls/Buttons/UploadFileBtn/UploadFileBtn'
import CustomCheckbox from '../../Controls/CustomCheckboxWithLabel/CustomCheckbox'
import StatusRequirementItem from '../../Controls/StatusRequirementItem/StatusRequirementItem'

import './RequirementsItem.scss'

interface RequirementsItemProps {
	requirement: RequirementItem
	docStatus: DocStatus
	setData: Dispatch<SetStateAction<RequirementItem[] | undefined>>
	userPermission: AccessNames[]
	onSettingClick: (id: string) => void
}

const RequirementsItem: FC<RequirementsItemProps> = ({
	requirement,
	docStatus,
	setData,
	userPermission,
																											 onSettingClick
}) => {
	const { id, name } = requirement

	const [progress, setProgress] = React.useState(10)
	const [statusDoc, setStatusDoc] = React.useState(docStatus)
	const [error, setError] = React.useState<string>('')
	const navigate = useNavigate()

	//Когда меняется статус документа через сокет, устанавливем локальный статус документа
	useEffect(() => {
		setStatusDoc(docStatus)
	}, [docStatus])

	const uploadFile = useCallback(async (formData: FormData) => {
		setStatusDoc(DocStatus.LOADING)
		let xhr = new XMLHttpRequest()

		xhr.upload.onprogress = function (event) {
			if (event.lengthComputable) {
				const progress = Math.round((event.loaded / event.total) * 100)
				if (progress >= 100) {
					setStatusDoc(DocStatus.TRTMNT)
				}
				setProgress(progress)
			}
		}

		xhr.open('POST', `${host}/Requirement/upload`, true)
		xhr.responseType = 'json'
		xhr.send(formData)

		xhr.onload = function () {
			if (xhr.status !== 200) {
				setError(xhr.statusText)
			} else {
				/*setStatusDoc(DocStatus.SUCCESS_FETCH)*/
			}
		}

		xhr.onerror = function () {
			setError(xhr.statusText)
		}
	}, [])

	const handleNavigateToEditor = () => {
		navigate(`${id}`)
	}

	const handleSettingClick = () => {
		onSettingClick(id)
	}

	return (
		<div className="req-item">
			<div className="req-item-group">
				<CustomCheckbox id={id} renderLabel={() => name} setData={setData} />
				<ButtonImg
					imgSrc={settingImg}
					alt={'Редактировать'}
					tooltipText={'Нет прав на редактирование'}
					onClick={handleSettingClick}
					status={statusDoc}
					userPermission={userPermission}
					btnSize='sm'
					disabled={(status) => status !== 'SuccessFetch' && status !== 'Exist' && status !== 'NotDoc'}
				/>
			</div>
			<div className="req-item-group">
				<StatusRequirementItem status={statusDoc} progress={progress} />
				<UploadFileBtn
					imgSrc={uploadImg}
					alt={'Загрузить'}
					onChange={uploadFile}
					id={id}
					userPermission={userPermission}
				/>
				<ButtonImg
					imgSrc={editImg}
					alt={'Изменить'}
					tooltipText={'Нет прав на чтение'}
					onClick={handleNavigateToEditor}
					status={statusDoc}
					userPermission={userPermission}
				/>

			</div>
		</div>
	)
}

export default RequirementsItem
