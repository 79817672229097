import { useDebounce } from 'hooks/useDebounce'
import { useEffect, useState } from 'react'

import { host } from '../Environment'

export const useOptions = <T,>(api: string) => {
	const [options, setOptions] = useState<T[]>([])

	const [input, setInput] = useState('')
	const debounceInput = useDebounce(input, 300)

	useEffect(() => {
		if (debounceInput) {
			fetch(host + api + `?name=${debounceInput}`)
				.then((response) => response.json())
				.then((options: T[]) => {
					setOptions(options)
				})
				.catch((err) => console.log(err))
		} else {
			setOptions([])
		}
	}, [api, debounceInput])

	const handleChange = (input: string) => {
		setInput(input)
	}

	return {
		options,
		handleChange
	}
}
