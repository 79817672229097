import { host } from 'Environment'
import { useEffect, useState } from 'react'

export const useFetch = <T>(
  uri: string,
  callbackResponse: (resp: Response) => Promise<any>
) => {
  const [data, setData] = useState<T>()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!uri) return
    fetch(uri)
      .then(callbackResponse)
      .then(setData)
      .then(() => setLoading(false))
      .catch((err) => {
        setError(err)
      })
  }, [callbackResponse, uri])

  const goFetch = () => {
    fetch(uri)
      .then(callbackResponse)
      .then(setData)
      .then(() => setLoading(false))
      .catch((err) => {
        setError(err)
      })
  }

  return {
    loading,
    data,
    error,
    setData,
    goFetch
  }
}

export const useDeferFetch = <TData>(
  uri: string,
  method: 'POST' | 'DELETE' | 'GET' = 'POST'
) => {

  const [data, setData] = useState<TData>()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  const onFetch = (body: any) => new Promise<void>((resolve, reject) => {
    setLoading(true)
    fetch(host + uri, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((response) => response.json())
      .then(setData)
      .catch((err) => {
        setError(err)
        reject()
      })
      .finally(() => {
        setLoading(false)
        resolve()
      })
  })

  return {
    loading,
    data,
    error,
    onFetch
  }
}