import React, {FC} from 'react';
import Dialog from '@mui/material/Dialog';

interface ModalProps {
    open: boolean
    handleClose: () => void
}

const Modal: FC<ModalProps> = ({open, handleClose, children}) => {
    return (
        <Dialog open={open} onClose={handleClose} sx={{
            padding: '20px 20px'
        }} maxWidth={'md'}>
            {children}
        </Dialog>
    );
};

export default Modal;