import React, {ChangeEvent, Dispatch, FC, KeyboardEvent, SetStateAction, useState} from 'react';
import searchIcon from '../../../assets/img/search-icon.png';

import './Search.scss';

interface SearchProps {
    setSearchStr: Dispatch<SetStateAction<string>>
}

const Search: FC<SearchProps> = ({setSearchStr}) => {

    const [search, setSearch] = useState('')

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter') {
            setSearchStr(search)
        }
    }

    const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
        if (!event.target.value) {
            setSearchStr('')
        }
    }

    return (
        <div className="search">
            <input value={search} type="text" placeholder="Поиск..." onChange={handleChangeInput}
                   onKeyDown={handleKeyDown}/>
            <button onClick={() => setSearchStr(search)}>
                <img src={searchIcon} alt="Поиск"/>
            </button>
        </div>
    );

};

export default Search;