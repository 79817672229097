import React, {ChangeEvent, CSSProperties, FC} from 'react';
import {MuiCustomInput} from "../../../shared/MySelectedComponents";
import {Select, SelectChangeEvent} from "@mui/material";
import {FormController, ReqFormInputs} from "../../../interfaces/interfaces";
import {useController} from "react-hook-form";

interface SelectProps<T> extends FormController<T> {
    style?: CSSProperties
}

const MyMuiSelect = <T,>({style, control, name, children}: React.PropsWithChildren<SelectProps<T>>) => {

    const {field} = useController({
        control,
        name
    })

    return (
        <Select
            style={style}
            onChange={field.onChange}
            value={field.value as string}
            variant={'outlined'}
            input={<MuiCustomInput />}
        >
            {children}
        </Select>
    );
};

export default MyMuiSelect;