import { Dispatch, SetStateAction } from 'react'
import { Editor as TinyMCEEditor } from 'tinymce'

export const cloneNode = <T extends Node>(node: T) => {
	return node.cloneNode(true) as T
}

export const pasteNewContent = (
	editor: TinyMCEEditor,
	type: string,
	closeDropDown: Dispatch<SetStateAction<boolean>>
) => {
	const node = editor.selection.getContent()
	createTypedText(editor, node, type)
	/*const innerHTML = `${node}<span class="__typed_span__" contentEditable="false">
		${type} 
		<img data-approve="true" src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADNSURBVHgBvdCxDcIwEAXQO4cBqKgQMlPQgdJQswLQ0AHKBFkgpKNDCIkKZiAiC7CCYY/4sC0lRMiKTUF+dfLp2ecDaDObjHe39+FU1/gj5AGDTJWcAGJvXIflmRe2QZIwd2IrBFomk+cBTTOAXQHynI5fV1+oa2aaBLOA2CXK+coX6nRIrQ2x6u6jfNArCnZyQfOyJAiVF9UJsVjBhwvqYDkiUy9hDVRQbTUJxREswfofvy9ogmbsskhDIaT8fAEJFk3QGj3B+tYfwb/zBopOYxv5I08lAAAAAElFTkSuQmCC' alt=""> 
		<img data-approve="false" src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD3SURBVHgBfZI7EoIwEIZ3l4HOwsaaI1A4jiUMHsAreDJqr2ADpYWFN5ALOBaODUhiFkQiefwNJPm/fWSDjzSJoyAqQanpmmxZXWtwaPCGRwBcsRdf+eYmAWI+RIDaFWBMontJysnAB2xgow/svWpBraBMUbUrgA1kP3M4GLZxSKJUtfwMXJboxIECKmzgsjrXOJVmBjCkgd8Eem+eADPQgFnP3TpFSaXBdiJbVJdK36P/zElMkgqwiHufTwF10LjVmebvgJyg6lGi8I4RfXPky3GNkSsgH8hL/toeUhhEJxIACxc4yhZA/Yf0FpD3mw7QEeDeSth/ABptxQ00FZxlAAAAAElFTkSuQmCC' alt="">
	</span>`
	editor.selection.setNode(
		editor.dom.create(
			'span',
			{
				class: '__typed__ai'
			},
			innerHTML
		)
	)
	editor.selection.setNode(
		editor.dom.create(
			'span',
			{
				class: '__typed__ai',
				'toolbox-text': type
			},
			node
		)
	)*/
	closeDropDown(false)
}


export const createTypedText = (editor: TinyMCEEditor,node: string, type: string) => {
	const styleForSpan = `
                display: inline;
                position: absolute;
                right: 0;
                top: -15px;
                text-indent: 0;
                font-size: 12px;
                font-weight: 300;           
                white-space: nowrap;
                line-height: 12px;
            `
	const innerHTML = `${node}<span style="${styleForSpan}" class="__typed_span__" contentEditable="false">${type.trim()}</span>`
	editor.selection.setNode(
		editor.dom.create(
			'span',
			{
				style: `position: relative;
                    display: inline;
                    background-color: #5CDB94;
                    padding: 2px;
                     border-radius: 3px;`,
				class: '__typed__'
			},
			innerHTML
		)
	)
}