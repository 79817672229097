import React, {
    FC,
    MouseEventHandler,
    useCallback,
    useContext,
    useRef,
    useState
} from 'react'
import 'react-notifications-component/dist/theme.css'
import {useNavigate, useParams} from 'react-router-dom'
import {EditorEvent, Editor as TinyMCEEditor} from 'tinymce'
import {ChangedDocContext} from '../../Context/context'
import closeIcon from '../../assets/img/Close.png'
import {ListItems} from '../../interfaces/interfaces'
import {
    addType,
    approveTypeAI,
    deleteTypeAI,
    deleteTypeContent,
    getObjectThree,
    translateFile
} from '../../services/editorHTML.service'
import AutocompleteType from '../Controls/AutocompleteType/AutocompleteType'
import DropDownWrapper from '../DropDownWrapper/DropDownWrapper'
import TypesHistoryList from '../TypesHistoryList/TypesHistoryList'

import './HTMLEditor.scss'
import BaseEditor from "../BaseEditor/BaseEditor";
import HistoryList from "../HistoryList/HistoryList";

interface HtmlEditorProps {
    getTreePreView: (nodes: NodeListOf<HTMLDivElement>) => void
    onTranslate?: (html: string) => void
    html: string
    onChange: (value: string) => void
    onSave: (html: string) => void
    translated?: boolean
}

const HtmlEditor: FC<HtmlEditorProps> = (({html, getTreePreView, onTranslate, onChange, onSave, translated}) => {

    const {id} = useParams<{ id: string }>()
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const [openDropDown, setOpenDropDown] = useState(false)
    const [positionDropDown, setPositionDropDown] = useState<{
        x: number
        y: number
    }>({x: 0, y: 0})
    const [dropDownRender, setDropDownRender] = useState<JSX.Element>()
    const [historyList, setHistoryList] = useState<
        { title: string; func: () => void }[]
    >([])

    const [isChanged, clearDocChanged] = useContext(ChangedDocContext)

    const navigate = useNavigate()

    const handleEditorSetup = (editor: TinyMCEEditor) => {
        editor.ui.registry.addButton('myCustomToolbarButton', {
            text: 'Установить тип',
            onAction: (api) => {
                addType(
                    editorRef,
                    setPositionDropDown,
                    setDropDownRender,
                    <AutocompleteType
                        editor={editorRef.current}
                        setOpenDropDown={setOpenDropDown}
                        addHistoryItem={setHistoryList}
                    />,
                    setOpenDropDown,
                    4
                )
            }
        })

        editor.ui.registry.addButton('myViewButton', {
            text: 'Показать превью',
            onAction: (api) => {
                const tinyIframe = document.querySelector('iframe')?.contentDocument
                if (tinyIframe) {
                    const nodes: NodeListOf<HTMLDivElement> =
                        tinyIframe.querySelectorAll('.__typed__')
                    if (nodes.length !== 0) {
                        getTreePreView(nodes)
                    }
                }
            }
        })

        editor.ui.registry.addButton('deleteContentType', {
            text: 'Удалить тип',
            onAction: (api) => {
                deleteTypeContent(editor)
            }
        })

        editor.ui.registry.addButton('mySaveDocButton', {
            text: 'Сохранить',
            onAction: (api) => {
                onSave(editor && editor.getContent({format: 'html'}))
            }
        })

        translated && editor.ui.registry.addButton('translate', {
            text: 'Перевести',
            onAction: (api) => {
                if (!editor) return;
                translateFile(editor.getContent({format: 'html'}))
                    .then(htmlObj => {
                        if (htmlObj?.translatedText && onTranslate) onTranslate(htmlObj?.translatedText)
                    })
            }
        })

        editor.ui.registry.addButton('history', {
            icon: 'insert-time',
            onAction: (api) => {
                addType(
                    editorRef,
                    setPositionDropDown,
                    setDropDownRender,
                    <HistoryList
                        idRequirement={id}
                        setOpenDropDown={setOpenDropDown}
                        setHtml={onChange}
                    />,
                    setOpenDropDown,
                    3
                )
            }
        })
    }

    const handleInit = (event: { [p: string]: any }, editor: TinyMCEEditor) => {
        editorRef.current = editor
        editor.on('click', handleValidateAI)
    }

    const handleClickAway = () => {
        if (openDropDown) {
            setOpenDropDown(false)
        }
    }

    const handleCloseClick: MouseEventHandler<HTMLImageElement> = (event) => {
        if (isChanged.current.state) {
            const isLeave = window.confirm('Выйти без сохранения?')
            if (!isLeave) {
                event.stopPropagation()
                event.preventDefault()
            } else {
                clearDocChanged()
                navigate(-1)
            }
        } else {
            navigate(-1)
        }
    }

    const deleteLastElement = useCallback(() => {
        setHistoryList((prev) => [...prev.slice(0, prev.length - 1)])
    }, [])

    const handleValidateAI = (event: EditorEvent<MouseEvent>) => {
        if (
            event.target.tagName === 'IMG' &&
            event.target.getAttribute('data-approve')
        ) {
            if (event.target.getAttribute('data-approve') === 'true') {
                if (!editorRef.current) return
                approveTypeAI(editorRef.current)
            } else {
                if (!editorRef.current) return
                deleteTypeAI(editorRef.current)
            }
        }
    }

    return (
        <>
            <BaseEditor
                onInit={handleInit}
                value={html}
                init={{
                    height: 900,
                    toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent history | ' +
                        'myCustomToolbarButton | myViewButton |' +
                        'getContentButton | deleteContentType | mySaveDocButton | translate',
                    setup: handleEditorSetup
                }}
                onClick={handleClickAway}
                onEditorChange={onChange}
            />
            <DropDownWrapper
                position={positionDropDown}
                open={openDropDown}
                renderComponent={dropDownRender}
                handleClickAway={handleClickAway}
            />
            {historyList.length !== 0 && (
                <TypesHistoryList
                    history={historyList}
                    deleteLastElem={deleteLastElement}
                />
            )}
            <img
                onClick={handleCloseClick}
                className="close-redactor"
                src={closeIcon}
                alt="Закрыть"
            />
        </>
    )
})

export default React.memo(HtmlEditor)
