export const authorization = (res: Response): Promise<Response> => new Promise((resolve, reject) => {
    if (res.redirected) {
        window.location.href = res.url
    }
    resolve(res)
})

export const validation = (res: Response, message: string): Promise<Response> => new Promise((resolve, reject) => {
    if (res.ok) {
        resolve(res)
    } else {
        reject({message})
    }
})