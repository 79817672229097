import React, {FC} from 'react';
import {Editor} from '@tinymce/tinymce-react'
import {IAllProps} from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";

interface EditorProps extends IAllProps {

}

const BaseEditor: FC<EditorProps> = ({init, ...props}) => {

    return (
        <Editor
            init={{
                height: 900,
                schema: 'html5',
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount fullpage'
                ],
                valid_children: '+body[style]',
                valid_elements: '*[*]',
                toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ',
                content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; line-height: 40px; }' +
                    '.mce-content-body [contentEditable=false][data-mce-selected] {outline: none; cursor: pointer;}' +
                    '.__typed__ai {background-color: rgba(92, 219, 148, 0.4); color: rgb(35, 98, 81); padding: 2px; display: inline; border-radius: 3px; position: relative;}' +
                    '.__typed__ai .__typed_span__ {display: none; position: absolute; top: -90%; left: 50%; background-color: white; transform: translateX(-50%);' +
                    'padding: 3px; line-height: 12px; white-space: nowrap; z-index: 10;' +
                    'font-size: 12px; box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);}' +
                    '.__typed__ai .__typed_span__ img:hover {cursor: pointer; opacity: 0.8;}' +
                    '.__typed__ai:hover {cursor: pointer;}' +
                    '.__typed__ai:hover .__typed_span__ {display: block}',
                ...init
            }}
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            {...props}
        />
    );
};

export default BaseEditor;