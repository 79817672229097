import * as signalR from '@microsoft/signalr'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import EditorPage from 'Pages/EditorPage'
import HomePage from 'Pages/HomePage'
import Layout from 'Pages/Layout'
import NotFoundPage from 'Pages/NotFoundPage'

import AuthProvider from 'providers/AuthProvider'
import RequirementsPage from 'Pages/RequirementsPage'
import ConnectionProvider from "./providers/ConnectionProvider";
import DocContextProvider from "./providers/DocContextProvider";
import PrivateRoute from 'hoc/PrivateRoute'

function App() {

	return (
		<AuthProvider>
			<ConnectionProvider>
				<DocContextProvider>
					<Routes>
						<Route
							path="/"
							element={
								<PrivateRoute>
									<Layout />
								</PrivateRoute>
							}
						>
							<Route index element={<HomePage />} />
							<Route path="requirements" element={<RequirementsPage />} />
							<Route path="requirements/:id" element={<EditorPage />} />
							<Route path="*" element={<NotFoundPage />} />
						</Route>
					</Routes>
				</DocContextProvider>
			</ConnectionProvider>
		</AuthProvider>
	)
}

export default App
