import { ChangedDocContext } from 'Context/context';
import React, {PropsWithChildren} from 'react';
import {useCheckSaveDoc} from "../hooks/useCheckSaveDoc";

interface DocContextProviderProps {

}
const DocContextProvider = ({children}: PropsWithChildren<DocContextProviderProps>) => {

    const [isDocChanged, setDocChanged, clearDocChanged] = useCheckSaveDoc()

    return (
        <ChangedDocContext.Provider
            value={[isDocChanged, setDocChanged, clearDocChanged]}
        >
            {children}
        </ChangedDocContext.Provider>
    );
};

export default DocContextProvider;