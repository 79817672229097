import {Store} from "react-notifications-component";

export const notify = (title:string, type: 'success' | 'danger' | 'info' | 'default' | 'warning', message?:string) => {
    Store.addNotification({
        title: title,
        type: type,
        message: message,
        insert: 'top',
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}