import {useEffect, useState} from "react";

export const useGetData = <T>(url: string): [T | null, boolean] => {
    const [data, setData] = useState<T | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
           try {
               const response = await fetch(url)
               const result = await response.json()

               if (response.ok) {
                   setData(result)
               } else {
                   throw new Error(result)
               }
           } catch(error) {
               console.error(error)
           } finally {
               setLoading(false)
           }
        })()
    }, [url])

    return [data, loading]
}