import React, {FC} from "react";

interface LabelProps {
    titleTooltip: string
    htmlFor: string;
    permission: boolean
}

const LabelWithPermission: FC<LabelProps> = ({htmlFor,permission, titleTooltip, children, }) => {

    return permission ? (
        <label htmlFor={htmlFor} >
            {children}
        </label>
    ) : (
        <label htmlFor={htmlFor} data-tooltip={titleTooltip}>
            {children}
        </label>
    )
}

export default LabelWithPermission;