import React, {CSSProperties, FC} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {CustomTextField, styleForPaperDatePicker} from "../../../shared/MyTextFieldComponents";
import {CustomPickersDay} from "../../../shared/MyDatePickerComponent";
import "moment/locale/ru";
import {FormController} from "../../../interfaces/interfaces";
import {useController} from "react-hook-form";
import {DateValidationError, ParseableDate} from "@mui/x-date-pickers/internals";


interface DatePickerProps<T> extends FormController<T> {
    style: CSSProperties,
    changeFields?: (date: Date | null) => void
}

const DatePicker= <T,>({style, control, name, rules, changeFields}: React.PropsWithChildren<DatePickerProps<T>>) => {

    const {field, fieldState} = useController({
        control,
        name,
        rules
    })

    const handleError = (reason: DateValidationError, value: ParseableDate<Date>) => {
        if (reason) {

        } else {

        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                mask="__.__.____"
                inputFormat="DD.MM.YYYY"
                value={field.value as Date}
                onChange={(event) => {
                    field.onChange(event)
                    changeFields && changeFields(event)
                }}
                renderInput={(params) => (
                    <div className="control-wrap" style={{...style}}>
                        <CustomTextField  {...params} inputProps={{
                            ...params.inputProps,
                            placeholder: 'ДД.ММ.ГГГГ'
                        }}/>
                        {fieldState.error && <p className="error-message">{fieldState.error.message}</p>}
                    </div>

                )}
                InputProps={{
                    className: fieldState.error && "error-input"
                }}
                leftArrowButtonText="Предыдущий месяц"
                rightArrowButtonText="Следующий месяц"
                renderDay={(day, selectedDates, pickersDayProps) => <CustomPickersDay {...pickersDayProps} />}
                PaperProps={styleForPaperDatePicker}
                onError={handleError}
            />
        </LocalizationProvider>
    );
};

export default DatePicker;