import {styled} from '@mui/material/styles';
import { PickersDay, PickersDayProps} from '@mui/x-date-pickers';

export const CustomPickersDay = styled((props: PickersDayProps<Date>) => (<PickersDay {...props}/>))`
  font-family: 'MontserratMedium', 'sans-serif';
  font-size: 16px;
  color: #05396B;
  &.Mui-selected {
    background-color: #5CDB94;
    &:hover {
      background-color: #5CDB94;
    }
    &:focus {
      background-color: #5CDB94;
    }
  }
  &.MuiPickersDay-today {
    border: 1px solid #5CDB94;
  }
`;