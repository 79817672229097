import React, {FC, useState} from 'react'
import {TreePreViewItem} from '../../interfaces/interfaces'
import AccordionList from './AccordionList/AccordionList'
import DnDPreViewProvider from "../../providers/DnDPreViewProvider";

import './TreeView.scss';
import {useDnDPreView} from "../../hooks/useDnDPreView";

interface ThreeViewProps {
    items: Array<TreePreViewItem>
    onChangePreView: (target: TreePreViewItem, destination: TreePreViewItem | null, type: 'summary' | 'detail' | 'wrapper') => void
}

const ThreeView: FC<ThreeViewProps> = ({items, onChangePreView}) => {

    const [isDroppable, setIsDroppable] = useState(false)
    const {target, destination, setDestination} = useDnDPreView()

    const handleDragOver: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setDestination(null)
        if (isDroppable) return;
        setIsDroppable(true)
    }

    const handleDragLeave = () => {
        if (!isDroppable) return;
        setIsDroppable(false)
    }

    const handleDrop = () => {
        setIsDroppable(false)
        if (!target) return;
        onChangePreView(target, destination, 'wrapper')
    }

    return (

            <div
                className={'tree-view_wrapper'}
                style={{backgroundColor: isDroppable ? 'rgba(92, 219, 148, 0.6)' : 'transparent'}}
                onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}
            >
                {items.map(item =>

                    <AccordionList
                        key={item.id}
                        item={item}
                        onChangePreView={onChangePreView}
                    />
                )}
            </div>

    )
}

export default ThreeView
