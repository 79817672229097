import { MutableRefObject, useRef } from "react";

export function useCheckSaveDoc(): [
	isDocChanged: MutableRefObject<{state: boolean}>,
	handleDocChanged: () => void,
	clearDocChanged: () => void
] {

	const isChanged = useRef<{state: boolean}>({state: false})

	const handleDocChanged = () => {
		isChanged.current.state = true
	}

	const clearDocChanged = () => {
		isChanged.current.state  = false
	}

	return [isChanged, handleDocChanged, clearDocChanged]
}
