import ButtonWithIcon from 'Components/Controls/Buttons/ButtonWithIcon/ButtonWithIcon'
import Search from 'Components/Controls/Search/Search'
import AddRequirementForm from 'Components/Form/AddRequirementForm'
import Modal from 'Components/Modal/Modal'
import RequirementsList from 'Components/RequirementsList/RequirementsList'
import { ConnectionContext } from 'Context/context'
import { host } from 'Environment'
import addIcon from 'assets/img/add-icon.png'
import delIcon from 'assets/img/del-icon.png'
import { useFetch } from 'hooks/fetch.hooks'
import { useAuth } from 'hooks/useAuth'
import { useModal } from 'hooks/useModal'
import {
	DocStatus,
	ReqFormInputs,
	RequirementItem,
	RequirementResponse
} from 'interfaces/interfaces'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { validation } from 'services/fetch.service'

const RequirementsPage = () => {
	const [requirementFormValues, setRequirementFormValues] =
		useState<ReqFormInputs>({
			pp: 0,
			date: new Date(),
			dateWith: new Date(),
			dateTo: null,
			actuality: true,
			permission: true,
			doc: null,
			docNumber: '',
			importer: null,
			months: '0'
		})
	const [updateReq, setIsUpdateRequirement] = useState(false)
	const [open, openModal, closeModal] = useModal(false)
	const [searchStr, setSearchStr] = useState('')

	const { connection } = useContext(ConnectionContext)

	const { user } = useAuth()

	const { data, loading, error, setData, goFetch } = useFetch<
		RequirementItem[]
	>(
		`${host}/Requirement/all${searchStr ? `?searchName=${searchStr}` : ''}`,
		useCallback((resp) => resp.json(), [])
	)

	useEffect(() => {
		connection?.on('AccessDocument', (state: DocStatus, id: string) => {
			setData((prev) =>
				prev?.map((req) => {
					if (req.id === id) {
						console.log(id)
						return {
							...req,
							state
						}
					}
					return req
				})
			)
		})
		return () => {
			connection?.off('AccessDocument')
		}
	}, [setData, connection])

	useEffect(() => {
		if (!open) {
			setRequirementFormValues({
				pp: 0,
				date: new Date(),
				dateWith: new Date(),
				dateTo: null,
				actuality: true,
				permission: true,
				doc: null,
				docNumber: '',
				importer: null,
				months: '0'
			})
			setIsUpdateRequirement(false)
		}
	}, [open])

	const handleDeleteClicked = () => {
		const deleteArr = data?.filter((req) => {
			if (req.checked) {
				return req.id
			}
			return false
		})
		fetch(`${host}/Requirement/delete`, {
			method: 'POST',
			body: JSON.stringify(deleteArr?.map((req) => req.id)),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then((res) => res.json())
			.then((length) => setData((prev) => prev?.filter((req) => !req.checked)))
			.catch((err) => console.log(err))
	}

	const handleSettingClick = (id: string) => {
		fetch(`${host}/Requirement/${id}`)
			.then((res) => validation(res, 'Что-то пошло не так'))
			.then((res) => res.json())
			.then((requirement: RequirementResponse) => {
				setRequirementFormValues({
					id: requirement.id,
					pp: requirement.pp,
					actuality: requirement.relevance,
					permission: requirement.permission,
					doc: {
						id: requirement.reqtype_id,
						code: requirement.reqtype
					},
					docNumber: requirement.reqnumb,
					date: requirement.reqdate,
					dateWith: requirement.period_beg,
					dateTo: requirement.period_end,
					months: requirement.permonths,
					importer: {
						id: requirement.importer_id,
						code: requirement.importCntr
					}
				})
				setIsUpdateRequirement(true)
				openModal()
			})
			.catch((err) => console.log(err))
	}

	return (
		<>
			<div className="req-wrap">
				<Search setSearchStr={setSearchStr} />
				<ButtonWithIcon
					icon={addIcon}
					title={'Добавить требование'}
					alt={'Добавить'}
					handleClick={openModal}
				/>
				<ButtonWithIcon
					icon={delIcon}
					title={'Удалить требование'}
					alt={'Удалить'}
					handleClick={handleDeleteClicked}
				/>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<p className="control-label" style={{ marginRight: '10px' }}>
						Вы авторизованы как: {user.userName}
					</p>
					<a href="/Authorization/logout">Выйти</a>
				</div>
				<RequirementsList
					onSettingClick={handleSettingClick}
					data={data}
					setData={setData}
					loading={loading}
					error={error}
					userPermission={user.accessList}
				/>
			</div>
			<Modal open={open} handleClose={closeModal}>
				<AddRequirementForm
					isUpdate={updateReq}
					initialState={requirementFormValues}
					closeModal={closeModal}
					setData={setData}
					onSuccessSave={goFetch}
				/>
			</Modal>
		</>
	)
}

export default RequirementsPage
