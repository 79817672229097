import React from 'react'
import { ReactNotifications } from 'react-notifications-component'
import { Outlet } from 'react-router-dom'

import Menu from '../Components/Menu/Menu'

import './StylePage.scss'

const Layout = () => {
	return (
		<>
			<div className="wrapper">
				<Menu />
				<Outlet />
				<ReactNotifications />
			</div>
		</>
	)
}

export default Layout
