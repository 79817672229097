import {styled} from '@mui/material/styles';
import {TextField} from "@mui/material";


export const CustomTextField = styled(TextField)(() => ({
    '&': {
        fontFamily: 'MontserratMedium, sans-serif',
        fontSize: '16px',
        color: '#05396B',
        width: '100%'
    },
    '& .MuiInputBase-root': {
        color: '#05396B',
        fontFamily: 'MontserratMedium, sans-serif',
        '&:hover fieldset': {
            borderColor: '#8DE4AF',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#8DE4AF',
        },
        '&.error-input fieldset': {
            borderWidth: '2px',
            borderColor: 'orangered'
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C4C4C4',
        borderRadius: '10px'
    },
    '& .MuiInputBase-input': {
        background: 'transparent',
        outline: 'none',
        padding: '5px 10px',
        height: '27px',
    }
}))

export const styleForPaperDatePicker = {
    sx: {
        '& .MuiCalendarPicker-root .PrivatePickersFadeTransitionGroup-root': {
            color: '#05396B',
            fontFamily: 'MontserratMedium, sans-serif'
        },
        '& .MuiCalendarPicker-root .MuiYearPicker-root .PrivatePickersYear-root .PrivatePickersYear-yearButton': {
            color: '#05396B',
            fontFamily: 'MontserratMedium, sans-serif',
            fontSize: '16px'
        },
        '& .MuiCalendarPicker-root .MuiYearPicker-root .PrivatePickersYear-root .PrivatePickersYear-yearButton.Mui-selected': {
            backgroundColor: '#5CDB94',
            color: 'white'
        }
    }
}