import { host } from 'Environment'
import { User } from 'interfaces/interfaces'
import React, { FC, ReactChild, ReactChildren, useEffect } from 'react'
import { authorization } from 'services/fetch.service'

import { useAuth } from 'hooks/useAuth'

interface PrivateRouteProps {
	children: ReactChild | ReactChildren
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
	const { user, signIn } = useAuth()

	useEffect(() => {
		fetch(`${host}/authorization/verification`, {
			method: 'GET',
			redirect: 'follow'
		})
			.then(authorization)
			.then((res) => res.json())
			.then((user: User) => {
				signIn(user)
			})
			.catch((err) => console.log(err))
	}, [signIn])

	if (!user.userName) {
		return null
	}

	return <>{children}</>
}

export default PrivateRoute
