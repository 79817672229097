import { Control, Path } from 'react-hook-form'
import { ErrorOption } from 'react-hook-form/dist/types/errors'
import { FieldPath } from 'react-hook-form/dist/types/path'

export interface ListItems {
	[key: string]: string | ListItems
}

export interface TreePreViewItem {
	id: string
	title: string
	content: string
	children: Array<TreePreViewItem>
}

export enum DocStatus {
	NOT_DOC = 'NotDoc',
	LOADING = 'Loading',
	TRTMNT = 'Treatment',
	EXIST = 'Exist',
	BUSY = 'Busy',
	SUCCESS_FETCH = 'SuccessFetch'
}

export interface RequirementItem {
	id: string
	name: string
	state: DocStatus
	checked?: boolean
}

export enum AccessNames {
	SAVE = 'Save',
	UPLOAD = 'Upload',
	READ = 'Read'
}

export interface User {
	userName: string
	accessList: AccessNames[]
}

//Для форм
export type ReqFormInputs = {
	id?: string
	pp: number
	actuality: boolean
	permission: boolean
	doc:  DocumentsResponse | null
	docNumber: string
	date: Date
	dateWith: Date | null
	dateTo: Date | null
	months: string
	importer: CountriesResponse | null
}

//Для передачи контроллера форм
export interface FormController<T> {
	control: Control<T, any>
	name: Path<T>
	rules?: {
		[key: string]: boolean | string
	}
	setError?: (
		name: FieldPath<T>,
		error: ErrorOption,
		options?: {
			shouldFocus: boolean
		}
	) => void
}

export interface CountriesResponse {
	id: number
	code: string
}

export interface DocumentsResponse {
	id: number
	code: string
}

export interface CreateRequirementRequest {
	id?: string | null
	importer_id: number
	period_beg: Date | null
	period_end: Date | null
	permission: boolean
	permonths: number
	pp: number
	relevance: boolean
	reqdate: Date
	reqnumb: string
	reqtype_id: number
}

export interface RequirementResponse {
	id?: string
	importCntr: string
	importer_id: number
	period_beg: Date | null
	period_end: Date | null
	permission: boolean
	permonths: string
	pp: number
	relevance: boolean
	reqdate: Date
	reqnumb: string
	reqtype: string,
	reqtype_id: number
}
