import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import * as signalR from "@microsoft/signalr";
import {host} from "../Environment";
import { ConnectionContext } from 'Context/context';

interface ConnectionProviderProps {

}

const ConnectionProvider = ({children}: PropsWithChildren<ConnectionProviderProps>) => {

    const [connection, setConnection] = useState<signalR.HubConnection | null>(null)

    useEffect(() => {
        setConnection(new signalR.HubConnectionBuilder()
            .withUrl(`${host}/requirements/access`)
            .build())
    }, []);

    useEffect(() => {
        if (!connection) return;
        connection.start().then(() => {
            console.log('connected')
        })
    }, [connection]);

    return (
        <ConnectionContext.Provider value={{ connection: connection }}>
            {children}
        </ConnectionContext.Provider>
    );
};

export default ConnectionProvider;