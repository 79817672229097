import cn from 'classnames'
import React, { FC } from 'react'

import { AccessNames, DocStatus } from '../../../../interfaces/interfaces'

import './ButtonImg.scss'


interface ButtonImgProps {
    imgSrc: string;
    alt: string;
    onClick: () => void
    status: DocStatus
    userPermission: AccessNames[]
    tooltipText: string
    btnSize?: 'lg' | 'sm'
    disabled?: (status: DocStatus) => boolean
}

const defaultDisabled = (status: DocStatus) => status !== 'SuccessFetch' && status !== 'Exist';

const ButtonImg: FC<ButtonImgProps> = ({
                                           imgSrc,
                                           alt,
                                           status,
                                           onClick,
                                           userPermission,
                                           tooltipText,
                                           btnSize = 'lg',
                                           disabled = defaultDisabled
}) => {

    const handleClick = () => {
        if (disabled(status) || !userPermission.includes(AccessNames.READ)) return
        onClick()
    }

    return userPermission.includes(AccessNames.READ) ? (
      <img src={imgSrc} alt={alt} className={cn('button-img', {
          disabled: disabled(status)
      }, {sm: btnSize === 'sm'})} onClick={handleClick} />
    ) : (
      <div data-tooltip={tooltipText}>
          <img src={imgSrc} alt={alt} className={cn('button-img disabled', {
            sm: btnSize === 'sm'
          })} />
      </div>
    )
};

export default ButtonImg;