import React, {useCallback, useContext, useEffect, useState} from "react";
import ThreeView from "../Components/ThreeView/ThreeView";
import {ListItems, TreePreViewItem} from "../interfaces/interfaces";
import {HTMLEditor} from "../Components";
import {host} from "../Environment";
import {useParams} from "react-router-dom";
import {ChangedDocContext, ConnectionContext} from "../Context/context";
import {Editor as TinyMCEEditor} from "tinymce";
import {getObjectThree, getTreePreView, saveDoc} from "../services/editorHTML.service";
import {findNode, findParent} from "../utils/utils";
import DnDPreViewProvider from "providers/DnDPreViewProvider";

const EditorPage = () => {

    const {id} = useParams<{ id: string }>()
    const {connection} = useContext(ConnectionContext)

    const [treePreView, setTreePreView] = useState<Array<TreePreViewItem>>([])
    const [html, setHtml] = useState('')
    const [translatedHTML, setTranslatedHTML] = useState('')
    const [isChanged, setDocChanged, clearDocChanged] = useContext(ChangedDocContext)

    useEffect(() => {
        let busy = false
        fetch(`${host}/Requirement/download`, {
            method: 'POST',
            body: JSON.stringify({
                /*clientId: connection?.connectionId,*/
                clientId: '55',
                documentId: id
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (res.status === 400) {
                    busy = true
                }
                return res.text()
            })
            .then((data) => setHtml(data))
            .catch((error) => {
                console.log(error)
            })

        return () => {
            if (!busy) {
                connection?.invoke('AccessDocument', {
                    State: 'Exist',
                    DocumentId: id
                })
            }
        }
    }, [connection, id])

    const handleTranslateHTML = (html: string) => {
        setTranslatedHTML(html)
    }

    const handleSave = (html: string) => {
        saveDoc(
            html,
            id,
            clearDocChanged
        )
    }

    const handleChangeHTML = (value: string) => {
        setHtml(prev => {
            if (prev) {
                setDocChanged()
                return value
            } else {
                return value
            }
        })
    }

    const handleChangeTranslatedHTML = (value: string) => {
        setTranslatedHTML(prev => {
            if (prev) {
                setDocChanged()
                return value
            } else {
                return value
            }
        })
    }

    const handleGetPreView = (nodes: NodeListOf<HTMLDivElement>) => {
        const treePreView = getTreePreView(nodes)
        console.log(treePreView)
        setTreePreView(treePreView)
    }

    const handleChangePreView = (target: TreePreViewItem, destination: TreePreViewItem | null, type: 'summary' | 'detail' | 'wrapper') => {

        if (target.id === destination?.id) return;

        const updateTree: Array<TreePreViewItem> = JSON.parse(JSON.stringify(treePreView))
        const targetParent = target ? findParent(updateTree, target, null) : null
        if (targetParent) {
            targetParent.children = targetParent.children.filter(child => child.id !== target.id);
        } else {
            const indexTargetElement = updateTree.findIndex(elem => elem.id === target.id)
            updateTree.splice(indexTargetElement, 1)
        }

        if (!destination) {
            updateTree.push(target)
            setTreePreView(updateTree)
            return
        }


        const destinationParent = type === 'summary' ? findParent(updateTree, destination, null) : null
        const foundedDestination = type === 'detail' ? findNode(updateTree, destination) : null

        if (type === 'summary') {
            if (destinationParent) {
                const indexDestinationElement = destinationParent.children.findIndex(elem => elem.id === destination.id)
                destinationParent.children = destinationParent.children.splice(indexDestinationElement, 0, target)
            } else {
                const indexDestinationElement = treePreView.findIndex(elem => elem.id === destination.id)
                updateTree.splice(indexDestinationElement, 0, target)
            }
        } else {
            if (foundedDestination?.children) {
                foundedDestination.children.push(target)
            }
        }

        setTreePreView(updateTree)
    }

    return (
        <div>
            <HTMLEditor
                translated
                html={html}
                getTreePreView={handleGetPreView}
                onTranslate={handleChangeHTML}
                onSave={handleSave}
                onChange={handleChangeHTML}
            />
            {/*{translatedHTML && <HTMLEditor
                html={translatedHTML}
                setViewObj={handleSetView}
                onSave={handleSave}
                onChange={handleChangeTranslatedHTML}
            />}*/}
            {treePreView && <div style={{padding: '20px 10px'}}>
                <DnDPreViewProvider>
                    <ThreeView items={treePreView} onChangePreView={handleChangePreView}/>
                </DnDPreViewProvider>
            </div>}
        </div>
    );
};

export default EditorPage;