import React, {CSSProperties, FC, useEffect} from 'react';
import cn from 'classnames';

import './Button.scss';

interface ButtonProps {
    type: 'submit' | 'button' | 'reset' | undefined
    color: 'primary' | 'abort'
    onClick?: () => void
    title: string
    style?: CSSProperties
    className?: string
    disabled?: boolean
}

const Button: FC<ButtonProps> = ({
                                     type,
                                     color,
                                     onClick,
                                     title,
                                     style,
                                     className,
                                     disabled
                                 }) => {

    return (
        <button type={type} onClick={onClick} style={style}
                className={cn('btn',
                    {'primary': color === 'primary'},
                    {'abort': color === 'abort'},
                    {className},
                    {disabled}
                )}
        >{title}</button>
    );
};

export default Button;