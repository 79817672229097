import signalR from '@microsoft/signalr'
import React, { createContext, MutableRefObject } from "react";

import { AccessNames, User } from '../interfaces/interfaces'
import {ListItem} from "@mui/material";
import {IDnDTreePreViewContext} from "../interfaces/context";


export const ConnectionContext = React.createContext<{connection: signalR.HubConnection | null}>({
    connection: null
})

export const ChangedDocContext = React.createContext<[isDocChanged: MutableRefObject<{state: boolean}>, handleDocChanged: () => void, clearDocChanged: () => void]>([
    {} as MutableRefObject<{state: boolean}>,
    () => {},
    () => {}
])

export const AuthContext = createContext<{ user: User, signIn: (user: User) => void }>({
    user: {
        userName: '',
        accessList: []
    },
    signIn: () => {}
})


export const DnDContext = createContext<IDnDTreePreViewContext>({} as IDnDTreePreViewContext)
