import React, {CSSProperties, FC, MouseEventHandler, useState} from 'react';

import './CheckboxWithlabel.scss';
import {FormController, ReqFormInputs} from "../../../interfaces/interfaces";
import {useController} from "react-hook-form";

interface CheckboxWithlabelProps<T> extends FormController<T> {
    id: string
    label: string
    style?: CSSProperties
}

const CheckboxWithlabel = <T,>({id, label, style, control, name}: React.PropsWithChildren<CheckboxWithlabelProps<T>>) => {

    const {field, formState} = useController({
        control,
        name
    })

    return (
        <>
            <input type="checkbox" className="form-custom-checkbox" id={id} onChange={field.onChange} checked={field.value as boolean}/>
            <label htmlFor={id} style={style}>{label}</label>
        </>
    );
};

export default CheckboxWithlabel;