import React, {FC, useState} from 'react';
import ProgressWithLabel from "../ProgressWithLabel/ProgressWithLabel";
import CircularWithLabel from "../CircularWithLabel/CircularWithLabel";
import {DocStatus} from "../../../interfaces/interfaces";

interface StatusRequirementItemProps {
    status: DocStatus
    progress: number
}

const StatusRequirementItem: FC<StatusRequirementItemProps> = ({status, progress}) => {

    const [successText, setSuccessText] = useState('Документ сохранён')

    if (status === DocStatus.TRTMNT) {
        return (
            <CircularWithLabel />
        )
    }

    if (status === DocStatus.SUCCESS_FETCH) {
        if (successText) {
            setTimeout(() => {setSuccessText('')}, 1000 )
            return (
                <p className={"item-info-text success"}>{successText}</p>
            )
        }
    }

    if (status === DocStatus.LOADING) {
        return (
            <ProgressWithLabel value={progress}/>
        )
    }

    if (status === DocStatus.NOT_DOC) {
        return (
            <p className={"item-info-text"}>Документ отсутствует</p>
        )
    }

    if (status === DocStatus.BUSY) {
        return (
            <p className={"item-info-text"}>Документ на данный момент занят</p>
        )
    }

    return (
        <>

        </>
    );
};

export default StatusRequirementItem;