import React, {ChangeEvent, FC, MouseEventHandler} from 'react';

import './UploadFileBtn.scss'
import {AccessNames} from "../../../../interfaces/interfaces";
import cn from "classnames";
import LabelWithPermission from "../../LabelWithPermission/LabelWithPermission";

interface UploadFileBtnProps {
    id: string
    imgSrc: string;
    alt: string;
    onChange: (formData: FormData) => void
    userPermission: AccessNames[]
}

const UploadFileBtn: FC<UploadFileBtnProps> = ({id,imgSrc, alt, onChange, userPermission}) => {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        console.log(!userPermission.includes(AccessNames.UPLOAD))
        if (!userPermission.includes(AccessNames.UPLOAD)) return;
        event.preventDefault()

        const formData = new FormData()

        if (!event.target.files) return
        let file = event.target.files[0];

        formData.append("file", file)
        formData.append("idRequirement", id)
        onChange(formData)

    }

    const handleCheckPermission: MouseEventHandler<HTMLImageElement> | undefined = (event) => {
        if (!userPermission.includes(AccessNames.UPLOAD)) {
            event.stopPropagation()
            event.preventDefault()
            return
        }
    }

    return (
        <>
            <LabelWithPermission htmlFor={id+'upload'} permission={userPermission.includes(AccessNames.UPLOAD)} titleTooltip="Нет прав на загрузку">
                <input id={id+'upload'} type="file" onChange={handleChange} accept=".doc, .docx, .pdf" />
                <img src={imgSrc} alt={alt} className={cn('button-img', {
                    disabled: !userPermission.includes(AccessNames.UPLOAD)
                })}  onClick={handleCheckPermission}/>
            </LabelWithPermission>
        </>
    );
};



export default UploadFileBtn;