import React, {FC, useState} from 'react';
import {DnDContext} from "../Context/context";
import {ListItems, TreePreViewItem} from "../interfaces/interfaces";

interface DnDPreViewProviderProps {
    children: React.ReactNode
}

const DnDPreViewProvider: FC<DnDPreViewProviderProps> = ({children}) => {

    const [target, setTarget] = useState<TreePreViewItem | null>(null)
    const [destination, setDestination] = useState<TreePreViewItem | null>(null)

    const handleSetTarget = (item: TreePreViewItem) => {
        setTarget(item)
    }

    const handleSetDestination = (item: TreePreViewItem | null) => [
        setDestination(item)
    ]

    return (
        <DnDContext.Provider value={{
            target: target,
            destination: destination,
            setDestination: handleSetDestination,
            setTarget: handleSetTarget
        }}>
            {children}
        </DnDContext.Provider>
    );
};

export default DnDPreViewProvider;