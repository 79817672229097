import React, {Dispatch, FC, SetStateAction} from 'react';

import {useGetData} from "../../hooks/useGetData";
import './HistoryList.scss';
import {host} from "../../Environment";
import moment from "moment";

interface HistoryListProps {
    idRequirement: string | undefined
    setOpenDropDown: Dispatch<SetStateAction<boolean>>
    setHtml: (html: string) => void
}

interface IHistoryItem {
    id: string
    date: string
    content: string
}

const HistoryList: FC<HistoryListProps> = ({idRequirement, setOpenDropDown, setHtml}) => {

    const [data, loading] = useGetData<Array<IHistoryItem>>(host + `/Requirement/${idRequirement}/history`)

    const handleClickItem =  (historyItem: IHistoryItem) => async () => {
        setHtml(historyItem.content)
    }

    return (
        <ul className={'history-list'}>
            {loading ? <p className={'history-list_loading'}>Загрузка...</p> : data?.map(historyItem =>
                <li key={historyItem.id} className={'history-list_item'} onClick={handleClickItem(historyItem)}>
                    {moment(historyItem.date).format('DD.MM.YYYY HH:mm')}
                </li>
            )}
        </ul>
    );
};

export default HistoryList;